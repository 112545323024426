import { useResource, TimelineTabPanel } from '@paddl/utils-react';
import React from 'react';

export const MyTimeline = () => {
  const { data, isLoading } = useResource(`${process.env.API_PREFIX_AT_WORK}/my-profile/timeline`);

  if (isLoading) return null;

  return <TimelineTabPanel timeline={data.timeline} />;
};
