import {
  Column,
  EditableContentBlock,
  ExperienceAnalyticsLockedState,
  FeedFooter,
  InnerContentWrapper,
  MarkDownSection,
  PageColumn,
  Section,
  SectionHeader,
  ProfileLinks
} from '@paddl/storybook';
import { useResource } from '@paddl/utils-react';
import {
  number
} from 'prop-types';
import React, { useContext } from 'react';

import { ProfileGraphs } from '../../components';
import { ModalContext } from '../../components/Context/ModalContext';
import { useMyProfile } from '../../components/Context/MyProfileContext';

const ExperienceSection = ({ numberOfActivities, profileId }) => {
  if (numberOfActivities >= 5) {
    return (
    <Section>
      <SectionHeader>Experience</SectionHeader>
      <div>
        <ProfileGraphs
          profileId={profileId}
        />
      </div>
    </Section>);
  }

  return (
      <Section>
        <SectionHeader>Experience</SectionHeader>
        <div style={{ padding: '16px' }}>
          <ExperienceAnalyticsLockedState stepsCompleted={numberOfActivities} />
        </div>
      </Section>
  );
};

ExperienceSection.propTypes = {
  numberOfActivities: number.isRequired,
  profileId: number.isRequired
};

export const MyProfileSummary = () => {
  const { setOpenModal } = useContext(ModalContext);
  const { data: statsData, isLoading: isStatsLoading } = useResource(`${process.env.API_PREFIX_AT_WORK}/my-profile/stats`);

  const { profile, isLoading: isProfileLoading } = useMyProfile();

  //   const { data: profileLinks, isLoading: isProfileLinksLoading } = useResource(`${process.env.API_PREFIX_V2}/profile-links/${responseProfile.id}`);
  //   const numberOfProfileLinks = profileLinks ? Object.values(profileLinks).filter((value) => !!value).length : 0;
  if (isStatsLoading || isProfileLoading) return null;
  const { activitiesCount } = statsData.stats;

  return (
    <InnerContentWrapper size="medium">
      <PageColumn>
        <Section>
          <Column style={{ gap: '16px' }}>
            <EditableContentBlock
              isEditable
              triggerModal={() => setOpenModal('edit-profile-summary')}
              title="Add your About me"
              data-test-id="profile-summary"
              subtitle="Share a short bio to tell more of your story."
              heading="About me"
              content={profile.aboutMe ? <MarkDownSection markDown={profile.aboutMe} /> : null}
            />
            {!isProfileLoading && (
                  <EditableContentBlock
                    isEditable
                    triggerModal={() => setOpenModal('edit-profile-links')}
                    title="Add Links"
                    subtitle="Share links to your website, portfolio or other profile."
                    data-test-id="profile-links"
                    heading="Links"
                    skeletonLoaderType="icon"
                    content={
                      profile.links.length > 0 ? (
                        <ProfileLinks links={profile.links} />
                      ) : null
                    }
                  />
            )}
          </Column>
        </Section>
        <ExperienceSection numberOfActivities={activitiesCount} profileId={profile.profileId} />

        <FeedFooter />
      </PageColumn>
    </InnerContentWrapper>
  );
};
