import {
  ModularPage,
  SnackbarProvider,
  SnackbarFeedback_V2
} from '@paddl/storybook';

import {
  MemberAppNavigation_Populated
} from '@paddl/utils-react';
import React from 'react';
import { CreateExperienceRecordPageContent } from './CreateExperienceRecordPageContent';
import { CreateExperienceRecordSideContent } from './CreateExperienceSideContent';

export const CreateExperienceRecord = () => {
  return (
      <SnackbarProvider>
        <ModularPage
          navigationContent={<MemberAppNavigation_Populated currentPage="create-experience-record" />}
          pageName="Add Experience"
          breadcrumbItems={[{ title: 'Add Experience' }]}
          pageContent={<CreateExperienceRecordPageContent />}
          sidebarContent={<CreateExperienceRecordSideContent />}
        />
        <SnackbarFeedback_V2 />

      </SnackbarProvider>);
};
