import {
  func, number, shape, string
} from 'prop-types';
import { Grid } from '@mui/material';
import {
  Artworks, Dropdown, TextField
} from '@paddl/storybook';
import React from 'react';
import { FormSection } from './FormSection';

export const DetailsFormSection = ({
  details, setDetails, commitment, setCommitment
}) => {
  return (

<FormSection icon={<Artworks.details />}>
<TextField
  label="Title"
  placeholder="e.g How to..."
  onChange={(title) => setDetails({ ...details, title })}
  isRequired
/>
<TextField
  label="Provider"
  placeholder="e.g. Mr. Beast"
  onChange={(providerName) => setDetails({ ...details, providerName })}
  isRequired
/>
<TextField
  label="Description"
  placeholder="e.g. what was this activity about?"
  onChange={(description) => setDetails({ ...details, description })}
  isRequired
/>

<Grid container spacing={2}>
    <Grid item xs={12} sm={4}>

        <TextField
          label="Time Commitment"
          type="number"
          placeholder="5"
          onChange={(commitmentValueString) => setCommitment(
            { ...commitment,  value: parseInt(commitmentValueString, 10) }
          )}
          isRequired
        />
    </Grid>
    <Grid item xs={12} sm={4}>
        <Dropdown
          defaultValue={'minute'}
          onChange={(unit) => setCommitment(
            {
              ...commitment, unit
            })}
          items={[
            { value: 'minute', text: 'Minutes' },
            { value: 'hour', text: 'Hours' },
            { value: 'week', text: 'Weeks' },
            { value: 'day', text: 'Days' },
            { value: 'year', text: 'Years' }
          ]}
        />
    </Grid>
    <Grid item xs={12} sm={4}>
        <Dropdown
          defaultValue={'total'}
          onChange={(period) => setCommitment(
            {
              ...commitment, period
            })}
          items={[
            { value: 'total', text: 'Total' },
            { value: 'day', text: 'Per Day' },
            { value: 'week', text: 'Per Week' },
            { value: 'year', text: 'Per Year' }
          ]}
        />

    </Grid>
</Grid>
</FormSection>
  );
};

DetailsFormSection.propTypes = {
  commitment: shape({
    value: number,
    unit: string,
    period: string
  }).isRequired,
  details: shape({
    title: string,
    description: string,
    providerName: string
  }).isRequired,
  setCommitment: func.isRequired,
  setDetails: func.isRequired
};
