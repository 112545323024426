import React, { useEffect } from 'react';
import {
  number,
  func,
  bool,
  shape
} from 'prop-types';
import { DoughnutChart, ActivityColorPaletteLight } from '@paddl/storybook';
import { activityCategories } from '@paddl/utils-js';
import { compose } from 'recompose';
import { withDataFetching } from '@paddl/utils-react';

export const XpByCategoryComp = ({
  profileId,
  shouldShowLegend,
  getXpByCategory: {
    fetch: fetchXpByCategory,
    response: xpByCategoryResponse,
    isSuccess
  }
}) => {
  useEffect(() => {
    fetchXpByCategory(
      `${process.env.API_PREFIX_V2}/profiles-statistics/xp-by-category/${profileId}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  return (
    isSuccess && (
      <DoughnutChart
        data-test-id="doughnut-chart"
        label="Xp by category"
        displayLegend={shouldShowLegend}
        labels={xpByCategoryResponse.xpByCategory.map(
          ({ categoryId }) =>
            activityCategories().find(
              (activityCategory) => activityCategory.categoryId === categoryId
            ).title
        )}
        backgroundColor={xpByCategoryResponse.xpByCategory.map(
          ({ categoryId }) =>
            ActivityColorPaletteLight[
              activityCategories().find(
                (activityCategory) => activityCategory.categoryId === categoryId
              ).id
            ]
        )}
        data={xpByCategoryResponse.xpByCategory.map(
          ({ collectedXpNumber }) => collectedXpNumber
        )}
      />
    )
  );
};

XpByCategoryComp.propTypes = {
  profileId: number.isRequired,
  shouldShowLegend: bool,
  getXpByCategory: shape({
    fetch: func.isRequired,
    isSuccess: bool,
    response: shape({})
  }).isRequired
};

XpByCategoryComp.defaultProps = {
  shouldShowLegend: false
};

export const XpByCategory = compose(
  withDataFetching({ propNameWrapper: 'getXpByCategory' })
)(XpByCategoryComp);
