import { Column, TextField, useSnackbarContext } from '@paddl/storybook';
import React, {
  useContext,
  useEffect, useState
} from 'react';
import Button from '@mui/material/Button';
import { useMyProfile } from '../Context/MyProfileContext';
import { ModalContext } from '../Context/ModalContext';
import { EditModalWrapper } from './EditModalWrapper';

class Link {
  constructor(title, prefix, value = '') {
    this.title = title;
    this.prefix = prefix;
    this.value = value;
  }
}

const createFromData = () => ({
  standardLinks: [
    new Link('Facebook', 'facebook.com/'),
    new Link('Linkedin', 'linkedin.com/in/'),
    new Link('Twitter', 'twitter.com/'),
    new Link('Instagram', 'instagram.com/'),
    new Link('Behance', 'behance.net/'),
    new Link('Dribbble', 'dribbble.com/'),
    new Link('Github', 'github.com/')
  ],

  customLinks: ['']

});

const populateForm = (links) => {
  let remainingLinks = links;
  const formData = createFromData();
  formData.standardLinks.forEach((standardLink) => {
    const matchingLink = links.find((link) => link.startsWith(`https://www.${standardLink.prefix}`));
    if (matchingLink) {
      // eslint-disable-next-line no-param-reassign
      standardLink.value = matchingLink.replace(`https://www.${standardLink.prefix}`, '');
      remainingLinks = remainingLinks.filter((link) => link !== matchingLink);
    }
  });
  formData.customLinks = remainingLinks.length > 0 ? remainingLinks : [''];

  return formData;
};

const getLinksFromForm = (formData) => {
  const standardLinks = formData.standardLinks
    .filter(({ value }) => !!value)
    .map(({ prefix, value }) => `https://www.${prefix}${value}`);
  const customLinks = formData.customLinks.filter((value) => !!value);

  return standardLinks.concat(customLinks);
};

const isValidUrl = (url) =>
  (url === '' || URL.canParse(url));

export const EditProfileLinksModal = () => {
  const [profileLinksFormState, setProfileLinksFormState] = useState(
    populateForm([])
  );

  const { openModal, closeModal } = useContext(ModalContext);
  const isOpen = openModal === 'edit-profile-links';
  const { showSnackbar } = useSnackbarContext();

  const { isLoading, profile, update } = useMyProfile();

  useEffect(() => {
    setProfileLinksFormState(populateForm(profile.links));
  },
  [profile]);
  const onSave = () => {
    update({ links: getLinksFromForm(profileLinksFormState) }).then(() => {
      showSnackbar({ message: 'Profile links successfully updated.', type: 'success' });
    }).catch(() => {
      showSnackbar({ message: 'Error updating profile links.', type: 'error' });
    }).finally(() => {
      closeModal();
    });
  };

  return (
    <EditModalWrapper
      data-test-id="modal-wrapper"
      hasBrandedColour={false}
      isLarge
      isOpen={isOpen}
      setIsOpen={closeModal}
      title="Edit Links"
      subtitle="Share links to your other profiles."
      cancelButton={<Button onClick={closeModal} variant="text">Cancel</Button>}
      saveButton={<Button disabled={isLoading} variant="contained" onClick={onSave}>Save</Button>}

    >
      <Column>
        {
            profileLinksFormState.standardLinks.map(({ title, prefix, value }, i) =>
<TextField
  key={title}
  label={title}
  startAdornment={prefix}
  placeholder="my-id"
  isControlled
  isDisabled={isLoading}
  defaultValue={value}
  onChange={(newValue) => {
    setProfileLinksFormState({
      ...profileLinksFormState,
      standardLinks: profileLinksFormState.standardLinks.toSpliced(i, 1, new Link(title, prefix, newValue))
    });
  }}
/>
            )
        }
    <TextField
      label="Website"
      data-test-id="website-link"
      placeholder="https://www.yoursitehere.com"
      hasError={!isValidUrl(profileLinksFormState.customLinks[0])}
      errorText="link must be a valid URL"
      isControlled
      isDisabled={isLoading}
      defaultValue={profileLinksFormState.customLinks[0]}
      onChange={(newValue) => {
        setProfileLinksFormState({
          ...profileLinksFormState,
          customLinks: [newValue]
        });
      }}
    />

      </Column>
    </EditModalWrapper>
  );
};
