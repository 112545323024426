import React from 'react';
import { number } from 'prop-types';
import Grid from '@mui/material/Grid';
import { ChartsWrapper, useWidth } from '@paddl/storybook';
import { XpByCategory, TopSkills } from '..';

export const ProfileGraphs = ({ profileId }) => {
  const width = useWidth();
  const isViewPortSmall = (width === 'xs' || width === 'sm');

  return (
    <Grid container justifyContent="space-between" spacing={4}>
        <Grid item xs={12} md={8}>
          <ChartsWrapper
            headLine="Top Skills"
            tagLine="By number of Activities"
            chartBackgroundColor="purple"
            chartPadding="small"
          >
            {profileId && (
              <TopSkills
                profileId={profileId}
              />
            )}
          </ChartsWrapper>
        </Grid>
        <Grid item xs={12} md={4}>
          <ChartsWrapper
            headLine="XP by Category"
            tagLine="% of Total Experience"
            chartBackgroundColor="yellow"
            chartPadding={'medium'}
          >
            {profileId && (
              <XpByCategory
                profileId={profileId}
                shouldShowLegend={isViewPortSmall}
              />
            )}
          </ChartsWrapper>
        </Grid>
    </Grid>
  );
};

ProfileGraphs.propTypes = {
  profileId: number
};

ProfileGraphs.defaultProps = {
  profileId: null
};
