import React, { useState, useContext } from 'react';
import {
  SidebarSectionHeader,
  Row, Column,
  Body,
  CircularPercentageChart,
  StepIndicatorDetailsPanel,
  StepIndicatorDetails,
  Artworks,
  ShowMoreButton,
  SidebarSection
} from '@paddl/storybook';
import { hardRedirect } from '@paddl/utils-js';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { number, shape } from 'prop-types';
import FitnessCenterSharp from '@mui/icons-material/FitnessCenterSharp';
import { ModalContext } from '../Context/ModalContext';

const SCORE_CONFIG = {
  url: 20,
  profileTitle: 10,
  activities: 30,
  profilePicture: 10,
  profileBanner: 5,
  aboutMe: 10
};

const getFullFilledProfileStrengths = ({
  activityCount = 0,
  profile = {}
}) => ({
  url: !!profile?.urlSlug,
  profileTitle: !!profile?.title,
  activities: activityCount >= 5,
  profilePicture: !!profile?.avatarUrl,
  profileBanner: profile?.bannerImageUrl?.indexOf('default-banners') <= -1,
  aboutMe: !!profile?.summary
});

const getAccumulativeSum = (accumulativeSum, sum) => accumulativeSum + sum;

const getUserStrengthsPercentage = (fullFilledProfileStrengths) => {
  const maxScore = Object.values(SCORE_CONFIG).reduce(getAccumulativeSum, 0);

  const profileScore = Object.entries(fullFilledProfileStrengths)
    .filter(([_, hasFullFilledRequirement]) => !!hasFullFilledRequirement)
    .map(([scoreKey, _]) => SCORE_CONFIG[scoreKey])
    .reduce(getAccumulativeSum, 0);

  return (profileScore / maxScore) * 100;
};
export const ProfileStrength = ({ activityCount, profile }) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const fullFilledProfileStrengths = getFullFilledProfileStrengths({ activityCount, profile });
  const percentage =  parseInt(getUserStrengthsPercentage(fullFilledProfileStrengths), 10);
  const { setOpenModal } = useContext(ModalContext);

  const {
    explanation: Explanation,
    five: Five,
    landscape: Landscape,
    link: Link,
    nameBadge: NameBadge,
    profilePicture: ProfilePicture
  } = Artworks;

  return (
    <SidebarSection>
      <Accordion
        square
        elevation={0}
        expanded={isAccordionOpen}
        TransitionProps={{ timeout: 250 }}
      >
        <AccordionSummary sx={{ padding: 0 }} data-test-id="accordion-header" onClick={() => setIsAccordionOpen(!isAccordionOpen)}>
        <Row style={{ gap: '32px' }}>
        <Column style={{ gap: '16px' }}>
        <SidebarSectionHeader
          icon={<FitnessCenterSharp />}
          title="Profile Strength"
        />
        <Body>Strengthen your profile to get noticed by employers and peers.</Body>
        </Column>
        <CircularPercentageChart percentage={percentage} />
        </Row>

        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <StepIndicatorDetailsPanel>
            <StepIndicatorDetails
              icon={<Link />}
              title="Create a Profile URL"
              subtitle="www.paddl.com/xp/yourname"
              isCompleted={fullFilledProfileStrengths.url}
              stepNumber={1}
              data-test-id="create-profile-url-link"
              onClick={() => hardRedirect('preferences')}
            />
            <StepIndicatorDetails
              icon={<NameBadge />}
              title="Write a Profile Title"
              subtitle="To share your current status."
              isCompleted={fullFilledProfileStrengths.profileTitle}
              stepNumber={2}
              data-test-id="create-a-profile-title"
              onClick={() => setOpenModal('edit-profile-information')}
            />
            <StepIndicatorDetails
              icon={<ProfilePicture />}
              title="Upload a Display Picture"
              subtitle="To help others recognise your Profile."
              isCompleted={fullFilledProfileStrengths.profilePicture}
              stepNumber={3}
              data-test-id="upload-display-picture"
              onClick={() => setOpenModal('edit-profile-picture')}
            />
            <StepIndicatorDetails
              icon={<Landscape />}
              title="Add a Profile Banner"
              subtitle="To personalise your Profile."
              isCompleted={fullFilledProfileStrengths.profileBanner}
              stepNumber={4}
              data-test-id="add-a-profile-banner"
              onClick={() => setOpenModal('edit-banner')}
            />
            <StepIndicatorDetails
              icon={<Explanation />}
              title="Write an About Me"
              subtitle="To tell your unique story."
              isCompleted={fullFilledProfileStrengths.aboutMe}
              stepNumber={5}
              data-test-id="add-a-profile-summary"
              onClick={() => setOpenModal('edit-profile-summary')}
            />
            <StepIndicatorDetails
              icon={<Five />}
              title="Add 5 Activities"
              subtitle="To unlock experience analytics."
              data-test-id="create-new-activities"
              isCompleted={fullFilledProfileStrengths.activities}
              stepNumber={6}
              onClick={() => hardRedirect('newActivities')}
            />
          </StepIndicatorDetailsPanel>
        </AccordionDetails>
      </Accordion>
      <ShowMoreButton isAccordionOpen={isAccordionOpen} setIsAccordionOpen={setIsAccordionOpen} showLessText="Hide suggestions" showMoreText={'Show suggestions'} />
    </SidebarSection>
  );
};

ProfileStrength.propTypes = {
  activityCount: number,
  profile: shape({})
};

ProfileStrength.defaultProps = {
  activityCount: 0,
  profile: {}
};
