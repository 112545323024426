import React, { useEffect, useState } from 'react';
import {
  shape,
  arrayOf,
  oneOfType
} from 'prop-types';
import {
  getActivityName,
  getOrganisationName,
  getContentfulFieldForEventId,
  urlStringForPathKey,
  getDataForPaddlGamesInTimeline,
  getContentfulData
} from '@paddl/utils-js';
import {
  ActivityTimelineCard,
  ActivityTimeline,
  EmptyPageFiller,
  FeedFooter,
  InnerContentWrapper,
  useViewportHeight, useWidth, PageColumn
} from '@paddl/storybook';
import styled from '@emotion/styled';
import Fab from '@mui/material/Fab';
import ArrowUpwardSharpIcon from '@mui/icons-material/ArrowUpwardSharp';

const ActivityLink =  styled.a`
  text-decoration: none;
`;

export const Activities = ({
  timelineResponse
}) => {
  const [paddlGamesData, setPaddlGamesData] = useState([]);
  const viewportHeight = useViewportHeight(global.innerHeight);
  const width = useWidth();

  const [scrollTop, setScrollTop] = useState(0);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  const showScrollToTop = width !== 'xs' && width !== 'sm' && scrollTop > (viewportHeight / 2);

  useEffect(() => {
    const onScroll = () => setScrollTop(window.scrollY);
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  useEffect(()  => {
    if (timelineResponse) {
      getDataForPaddlGamesInTimeline(
        timelineResponse,
        (id) =>
          getContentfulData({
            content_type: 'paddlGames',
            'fields.eventId': id
          })
      ).then((allPaddlGames) => (
        setPaddlGamesData(allPaddlGames)
      ));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelineResponse]);

  return (
    <InnerContentWrapper size="medium">
      <PageColumn>
          {timelineResponse && timelineResponse.length > 0 ? (
            <div>
            <ActivityTimeline activities={timelineResponse}>
              {timelineResponse.map(
                ({
                  id,
                  variantId,
                  details,
                  xpNumber,
                  skillsCount,
                  duration,
                  percentageComplete
                }) => (
                  <ActivityLink
                    href={urlStringForPathKey('activitiesById', { id })}
                    key={id}
                  >
                    <ActivityTimelineCard
                      variantId={variantId}
                      title={
                        variantId !== 4
                          ? getActivityName(details)
                          : getContentfulFieldForEventId(
                            paddlGamesData,
                            details.eventId
                          )?.name
                      }
                      subtitle={
                        variantId !== 4
                          ? getOrganisationName(details)
                          : getContentfulFieldForEventId(
                            paddlGamesData,
                            details.eventId
                          )?.hostCompany?.title
                      }
                      xpNumber={xpNumber}
                      skills={skillsCount}
                      duration={duration}
                      percentageComplete={percentageComplete}
                    />
                  </ActivityLink>
                )
              )}
            </ActivityTimeline>
            </div>
          ) : null}
          {
            timelineResponse &&
            timelineResponse.length === 0 &&
            <EmptyPageFiller
              data-test-id="no-activities"
              firstLine="We can’t find any Activities on this profile."
              secondLine="They must be busy learning!"
            />
          }

      <FeedFooter />
      </PageColumn>
      {showScrollToTop && (
      // position the FAB relative to the bottom of the screen, and the right of the InnerContentWrapper
      // https://stackoverflow.com/questions/3303173/position-element-fixed-vertically-absolute-horizontally
       <div style={{ position: 'relative', float: 'right' }}>
        <div style={{ position: 'absolute', right: '64px' }}>
       <Fab onClick={scrollToTop} sx={{ position: 'fixed', bottom: '32px' }} color="primary" aria-label="scroll to top"><ArrowUpwardSharpIcon /></Fab>
        </div>
       </div>
      )}
    </InnerContentWrapper>
  );
};

Activities.propTypes = {
  timelineResponse: oneOfType([arrayOf(shape({})), shape({})])
};

Activities.defaultProps = {
  timelineResponse: {}
};
