import React, { useContext } from 'react';
import { number, oneOfType, string } from 'prop-types';
import { urlStringForPathKey, trackEvent } from '@paddl/utils-js';
import {
  Modal,
  SocialMediaShare,
  ShareLink,
  Avatar
} from '@paddl/storybook';
import {
  ShareModalHeader,
  UrlShareWrapper,
  ShareModalContentWrapper
} from './ShareProfileModal.styles';
import { ModalContext } from '../Context/ModalContext';

export const ShareProfileModal = ({
  profilePath,
  avatarUrl
}) => {
  const { openModal, closeModal } = useContext(ModalContext);
  const isModalOpen = openModal === 'share-profile';

  const shareLink = urlStringForPathKey('publicProfileBySlug', {
    slug: profilePath
  });

  return (
    <Modal
      hasBrandedColour={false}
      isOpen={isModalOpen}
      handleExit={closeModal}
      hasOverflow
      data-test-id="share-profile-modal"
    >
      <ShareModalContentWrapper>
      <ShareModalHeader>
          { avatarUrl
            ? <div className="avatar-wrapper">
                <Avatar
                  imageUrl={avatarUrl}
                  imageAlt="profile avatar"
                  size="medium-large"
                  hasBorder
                />
              </div>
            : null }
        <h2>Share Profile</h2>
      </ShareModalHeader>
      <UrlShareWrapper>
        <ShareLink
          url={shareLink}
          data-test-id="share-link"
          onClick={() => {
            trackEvent('Shared Profile Link', {
              shareType: 'link'
            });
          }}
        />
      </UrlShareWrapper>
      <SocialMediaShare
        url={shareLink}
        data-test-id="social-media-share"
        onClick={(provider) => {
          trackEvent('Shared Profile Link', {
            shareType: provider
          });
        }}
      />
      </ShareModalContentWrapper>
    </Modal>
  );
};

ShareProfileModal.propTypes = {
  profilePath: oneOfType([string, number]),
  avatarUrl: string
};

ShareProfileModal.defaultProps = {
  avatarUrl: '',
  profilePath: ''
};
