import Button from '@mui/material/Button';
import {
  useWidth,
  useSnackbarContext
} from '@paddl/storybook';
import {
  func,
  number,
  string,
  bool
} from 'prop-types';
import React, {
  useCallback,
  useContext, useState
} from 'react';
import { put } from '@vercel/blob';
import { useMyProfile } from '../../Context/MyProfileContext';
import { ModalContext } from '../../Context/ModalContext';
import { EditModalWrapper } from '../EditModalWrapper';
import { BannerFileSelect } from './BannerFileSelect';
import { BannerResizer } from './BannerResizer';

import {
  BannerPreview,
  BannersWrapper,
  DefaultBanner,
  Headline
} from './EditProfileBannerModal.styles';
import { defaultBanners } from './defaultBanners';

const EditProfileBannerModalComp = ({
  updateProfile,
  profileId,
  bannerUrl,
  isLoading
}) => {
  const width = useWidth();
  const { openModal, closeModal } = useContext(ModalContext);
  const isOpen = openModal === 'edit-banner';
  const { showSnackbar } = useSnackbarContext();

  const [originalFile, setOriginalFile] = useState();
  const [resizedFile, setResizedFile] = useState();
  const [selectedBannerUrl, setSelectedBannerUrl] = useState(bannerUrl);
  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const isLargeScreen = width !== 'xs' && width !== 'sm';

  const onRemove = useCallback(() => {
    setIsDirty(false);
    setOriginalFile(undefined);
    setSelectedBannerUrl(undefined);
    setResizedFile(undefined);
  }, []);

  const onSave = useCallback(() => {
    const saveBanner = async () => {
      const blob = (resizedFile?.blob || originalFile);
      if (blob) {
        const { url } = await put(`profile-banners/custom/${profileId}/${originalFile.name}`, blob, {
          access: 'public'
        });
        await updateProfile({ bannerUrl: url });
      } else {
        await  updateProfile({ bannerUrl: selectedBannerUrl });
      }
    };

    setIsSaving(true);
    saveBanner().then(() => {
      showSnackbar({ message: 'Profile banner successfully updated.', type: 'success' });
      setIsDirty(false);
    }).catch(() => {
      showSnackbar({ message: 'Error updating profile banner', type: 'error' });
    }).finally(() => {
      setIsSaving(false);
      closeModal();
    });
  }, [closeModal, originalFile, profileId, resizedFile?.blob, selectedBannerUrl, showSnackbar, updateProfile]);

  return (
    <EditModalWrapper
      data-test-id="modal-wrapper"
      hasBrandedColour={false}
      isOpen={isOpen}
      setIsOpen={closeModal}
      title="Edit Profile Banner"
      subtitle="Personalise your Profile with a banner image."
      cancelButton={<Button onClick={closeModal} variant="text">Cancel</Button>}
      saveButton={
        <Button disabled={!isDirty || isSaving || isLoading} variant="contained" onClick={onSave}>Save</Button>
      }
      isLarge={width !== 'xs' && width !== 'sm'}
    >
      {!originalFile && !selectedBannerUrl && <BannerFileSelect
        onFileSelect={(file) => {
          setSelectedBannerUrl(undefined);
          setOriginalFile(file);
          setResizedFile(undefined);
          setIsDirty(true);
        }}
      />}
      {selectedBannerUrl &&
      <>
        <DefaultBanner imgSourceUrl={selectedBannerUrl} />
        <Button variant="text" onClick={onRemove}>Remove</Button>
      </>}
      {originalFile && isDirty &&
      <>
        <BannerResizer
          onResize={setResizedFile}
          originalFile={originalFile}
        />
        <Button variant="text" onClick={onRemove}>Remove</Button>
      </>}
      <Headline>... Or choose from Paddl artwork:</Headline>
      <BannersWrapper isLargeScreen={isLargeScreen}>
        {defaultBanners.map(({ url, borderColour, id }) => (
          <BannerPreview
            key={id}
            title={`default banner - ${id}`}
            imgSourceUrl={url}
            isLargeScreen={isLargeScreen}
            isSelected={url === selectedBannerUrl}
            borderColour={borderColour}
            onClick={() => {
              setOriginalFile(undefined);
              setResizedFile(undefined);
              setIsDirty(true);
              setSelectedBannerUrl(url);
            }}
          />
        ))}
      </BannersWrapper>
    </EditModalWrapper>
  );
};

EditProfileBannerModalComp.defaultProps = {
  bannerUrl: undefined,
  isLoading: false
};

EditProfileBannerModalComp.propTypes = {
  updateProfile: func.isRequired,
  bannerUrl: string,
  profileId: number.isRequired,
  isLoading: bool
};

export const EditProfileBannerModal = () => {
  const { isLoading, profile, update } = useMyProfile();

  return <EditProfileBannerModalComp isLoading={isLoading} profileId={profile?.profileId} bannerUrl={profile?.bannerUrl} updateProfile={update} />;
};
