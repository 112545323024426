import React, { useEffect, useCallback } from 'react';
import {
  number,
  func,
  bool,
  shape
} from 'prop-types';
import { compose } from 'recompose';
import { BarChart } from '@paddl/storybook';
import { withDataFetching } from '@paddl/utils-react';

const getChartProps = (skills) => {
  const labels = [];
  const data = [];

  skills.forEach(({ name, count }) => {
    labels.push(name);
    data.push(count);
  });

  return { labels, data };
};

export const TopSkillsComp = ({
  profileId,
  getSkills: {
    fetch: fetchSkills,
    response: skillsResponse,
    isSuccess
  }
}) => {
  useEffect(() => {
    fetchSkills(
      `${process.env.API_PREFIX_V2}/profiles-statistics/skills/${profileId}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  const { labels, data } = useCallback(() => {
    const filteredSkills = skillsResponse?.skills?.filter((_, index) =>  index < 5) || [];

    return getChartProps(filteredSkills);
  }, [skillsResponse])();

  return (
    isSuccess && (
      <BarChart
        data-test-id="bar-chart"
        labels={labels}
        data={data}
      />
    )
  );
};

TopSkillsComp.propTypes = {
  profileId: number.isRequired,
  getSkills: shape({
    fetch: func.isRequired,
    isSuccess: bool,
    response: shape({})
  }).isRequired
};

TopSkillsComp.defaultProps = {};

export const TopSkills = compose(
  withDataFetching({ propNameWrapper: 'getSkills' })
)(TopSkillsComp);
