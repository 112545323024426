import {
  Avatar,
  Body,
  Colors,
  Column,
  H1,
  H4,
  InnerContentWrapper,
  LabelText, MainContentWrapper,
  ModularPage,
  PageColumn,
  ProgressCard,
  Row
} from '@paddl/storybook';
import PropTypes, { shape, string } from 'prop-types';

import styled from '@emotion/styled';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import {
  MemberAppNavigation_Populated,
  Metadata,
  useResource
} from '@paddl/utils-react';
import React from 'react';
import { ExperienceRecordSideContent } from './ExperienceRecordSideContent';

const Section = styled(Column)`
  gap: 32px;
`;

const SkillContainer = styled.div`
  display: flex; 
  gap: 16px;
  flex-wrap: wrap;
`;

const SkillChip = styled(Chip)`
  padding: 8px 16px;
  box-shadow: none;
  border-radius: 4px;
  border: 1px solid ${Colors.new_grey_lighter};\

   .MuiChip-label {
    padding: 0;
    color: ${Colors.new_grey}
  }
`;

const ProviderSnippet = ({ providerName }) => {
  return (<Paper
    elevation={0}
    sx={{
      backgroundColor: Colors.grey_magnolia,
      padding: '16px',
      color: Colors.new_grey
    }}
  >
    <Row style={{ gap: '16px' }}>
      <Avatar
        imageAlt={`${providerName}'s avatar`}
        imageUrl="https://vafiquybohuan0wt.public.blob.vercel-storage.com/ui-assets/default-provider-logo.svg"
        variant="rounded"
        size="small"

      />

      <Column style={{
        gap: '4px', alignItems: 'start', overflow: 'hidden', minWidth: 0
      }}
      >
        <H4 style={{
          width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'
        }}
        >
          {providerName}
        </H4>
        <LabelText>Provider</LabelText>
      </Column>
    </Row>
          </Paper>);
};

ProviderSnippet.propTypes = {
  providerName: PropTypes.string.isRequired
};

const PageContent = ({ experienceRecord }) => {
  const {
    title, description, providerName, skills, progress
  } = experienceRecord;

  return (<MainContentWrapper withGhostNav>
    <Metadata title="Join Workspace | Paddl" />
    <InnerContentWrapper size="medium">
      <PageColumn>
        <Section>
          <Column style={{ gap: '16px' }}>
            <H1>{title}</H1>
            {/* <LabelText>TODO: duration</LabelText> */}
          </Column>
          <ProviderSnippet providerName={providerName} />
          <Body>
            {description}
          </Body>
          <ProgressCard
            isCurrent={progress.isCurrent}
            startDate={progress.startDate}
            endDate={progress.endDate}
          />
        </Section>
        <Section><H1>Skills</H1><SkillContainer>{skills.map((skill) => <SkillChip key={skill} label={skill} />)}</SkillContainer></Section>
      </PageColumn>
    </InnerContentWrapper>
          </MainContentWrapper>);
};

PageContent.propTypes = {
  experienceRecord: PropTypes.shape({
    description: PropTypes.string,
    providerName: PropTypes.string,
    title: PropTypes.string,
    skills: PropTypes.arrayOf(PropTypes.string),
    progress: PropTypes.shape({
      isCurrent: PropTypes.bool,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string
    }).isRequired
  }).isRequired
};

export const ExperienceRecord = ({ match: { params: { experienceRecordId } } }) => {
  const { data, isLoading } = useResource(`${process.env.AT_WORK_API}/v2/experience-record/${experienceRecordId}`);
  if (isLoading) return null;

  return (

    <ModularPage
      navigationContent={<MemberAppNavigation_Populated currentPage="profile" />}
      pageName="Profile"
      pageContent={!data?.experienceRecord ? null : <PageContent experienceRecord={data.experienceRecord} />}
      sidebarContent={<ExperienceRecordSideContent skillCount={data?.experienceRecord?.skills?.length} />}
      breadcrumbItems={
        [
          {
            title: data?.experienceRecord?.title || 'Experience'
          }
        ]
      }
    />

  );
};

ExperienceRecord.propTypes = {
  match: shape({
    params: shape({
      experienceRecordId: string
    }).isRequired
  }).isRequired
};
