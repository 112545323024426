import {
  Button,
  InnerContentWrapper,
  MainContentWrapper,
  PageColumn,
  PageHeader,
  useSnackbarContext
} from '@paddl/storybook';
import React, { useState } from 'react';
import {
  hardRedirect,
  trackEvent
} from '@paddl/utils-js';
import { useSubmitData } from '@paddl/utils-react';
import { DetailsFormSection } from './FormSections/DetailsFormSection';
import { ProgressFormSection } from './FormSections/ProgressFormSection';
import { SkillsFormSection } from './FormSections/SkillsFormSection';

const shadow = 'rgba(0, 0, 0, .1)';

const footerHeight = '200px';

export const CreateExperienceRecordPageContent = () => {
  const { submit, isLoading } = useSubmitData();
  const [formState, setFormState] = useState({
    title: null,
    description: null,
    providerName: null,
    commitment: {
      value: null,
      unit: 'minute',
      period: 'total'
    },
    progress: {
      startDate: null,
      endDate: null,
      isCurrent: false
    },
    skills: []
  });

  const { showSnackbar } = useSnackbarContext();

  const onComplete = () => {
    trackEvent('Created Experience Record');

    submit(`${process.env.AT_WORK_API}/v2/experience-record/`, {
      ...formState,
      experienceVariant: 'prize'

    }).then((response) => {
      hardRedirect('experience-record', { id: response.createdExperienceRecord.experienceRecordId });
    }).catch(() => {
      showSnackbar({ message: 'Error creating experience record.', type: 'error' });
    });
  };

  return (<div
    style={{
      position: 'relative'
    }}
  >
    <div style={{ minHeight: `calc(100vh - ${footerHeight})`, display: 'flex', flexDirection: 'column' }}>
      <MainContentWrapper
        colorVariant="dark"
        sx={{ minHeight: 0 }}
        withGhostNav
      >
        <InnerContentWrapper size="medium">
          <PageColumn>
            <PageHeader
              heading="Add an Experience to your Profile"
              tagline="Paste a link to your learning experience and let Paddl AI do the rest!"
            />
          </PageColumn>
        </InnerContentWrapper>
      </MainContentWrapper>

      <div style={{ backgroundColor: 'white', flexGrow: 1 }}>
        <InnerContentWrapper size="small">

          <PageColumn>
            <DetailsFormSection
              details={formState}
              setDetails={setFormState}
              commitment={formState.commitment}
              setCommitment={(newCommitment) => setFormState({
                ...formState,
                commitment: newCommitment
              })}
            />

            <ProgressFormSection
              progress={formState.progress}
              setProgress={(newProgress) => setFormState({
                ...formState,
                progress: newProgress
              })}
            />
            <SkillsFormSection
              selectedSkills={formState.skills}
              setSelectedSkills={(skills) =>
                setFormState((oldState) => ({
                  ...oldState,
                  skills
                }))}
            />

          </PageColumn>

        </InnerContentWrapper>

      </div>
    </div>

    <div style={{
      position: 'absolute', width: '100%', height: footerHeight, boxShadow: `0px -2px 2px ${shadow}`
    }}
    >

      <Button disabled={isLoading} variant="contained" color="green" onClick={onComplete}>Submit Experience</Button>
    </div>
          </div>
  );
};
