import React, { useState, useContext } from 'react';
import { IconButton, Icons } from '@paddl/storybook';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
  ShowMoreIconWrapper,
  StyledMuiMenu
} from './MenuItems.styles';

import { ModalContext } from '../Context/ModalContext';

export const MenuItems = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { setOpenModal } = useContext(ModalContext);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const {
    profileDetails: ProfileDetails,
    profileBanner: ProfileBanner,
    profilePicture: ProfilePicture
  } = Icons;

  return (
    <>
      <ShowMoreIconWrapper>
        <IconButton
          onClick={handleClick}
          size="small"
          color="grey"
          icon="navMore"
          variant="plained"
          label="show more"
        />
      </ShowMoreIconWrapper>
      <StyledMuiMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transitionDuration={250}
        autoFocus={false}
        classes={{ paper: 'custom-paper', list: 'custom-list' }}
      >
        <MenuItem
          disableRipple
          data-test-id="menu-item"
          onClick={() => {
            handleClose();
            setOpenModal('edit-profile-information');
          }}
          classes={{ gutters: 'icon-button' }}
        >
          <ListItemIcon>
            <ProfileDetails />
          </ListItemIcon>
          <ListItemText>Edit Profile Details</ListItemText>
        </MenuItem>
        <MenuItem
          disableRipple
          onClick={() => {
            handleClose();
            setOpenModal('edit-profile-picture');
          }}
          classes={{ gutters: 'icon-button' }}
        >
          <ListItemIcon>
            <ProfilePicture />
          </ListItemIcon>
          <ListItemText>Edit Profile Picture</ListItemText>
        </MenuItem>
        <MenuItem
          disableRipple
          onClick={() => {
            handleClose();
            setOpenModal('edit-banner');
          }}
          classes={{ gutters: 'icon-button' }}
        >
          <ListItemIcon>
            <ProfileBanner />
          </ListItemIcon>
          <ListItemText>Edit Profile Banner</ListItemText>
        </MenuItem>
      </StyledMuiMenu>
    </>
  );
};

MenuItems.defaultProps = {
  profile: {
    title: '',
    avatarUrl: '',
    bannerImageUrl: '',
    summary: ''
  }
};
