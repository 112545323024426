import {
  ModularPage,
  SnackbarProvider
} from '@paddl/storybook';

import React, { useMemo, useState } from 'react';
import {
  MemberAppNavigation_Populated
} from '@paddl/utils-react';
import { MyProfileProvider } from '../../components/Context/MyProfileContext';
import { ModalContext } from '../../components/Context/ModalContext';
import { MyProfilePageContent } from './MyProfilePageContent';
import { MyProfileSideContent } from './MyProfileSideContent';
import { ShareProfileActionButton } from '../Profile/ShareProfileActionButton';
import { MenuItems } from '../../components';

export const MyProfile = () => {
  const [openModal, setOpenModal] = useState(null);

  const modalContext = useMemo(() => {
    return {
      openModal, setOpenModal, closeModal: () => setOpenModal(null)
    };
  }, [openModal, setOpenModal]);

  const pageActionItems = [
    <MenuItems
      key="edit-menu"
    />,
      <ShareProfileActionButton />];

  return (
    <SnackbarProvider>
    <ModalContext.Provider value={modalContext}>
      <MyProfileProvider>

      <ModularPage
        navigationContent={<MemberAppNavigation_Populated currentPage="profile" />}
        pageName="Profile"
        pageContent={<MyProfilePageContent />}
        pageActionItems={pageActionItems}
        sidebarContent={<MyProfileSideContent />}
      />

      </MyProfileProvider>
    </ModalContext.Provider>
    </SnackbarProvider>);
};
