import { string } from 'prop-types';
import React, { useMemo, useState } from 'react';
import { MemberAppNavigation_Populated } from '@paddl/utils-react';
import { ModularPage } from '@paddl/storybook';
import { ModalContext } from '../../components/Context/ModalContext';
import { ProfilePageContent } from './ProfilePageContent';
import {
  ProfileSuggestions
} from '../../components';
import { ShareProfileActionButton } from './ShareProfileActionButton';

export const Profile = ({
  profileIdOrSlug
}) => {
  const [openModal, setOpenModal] = useState(null);

  const modalContext = useMemo(() => {
    return {
      openModal, setOpenModal, closeModal: () => setOpenModal(null)
    };
  }, [openModal, setOpenModal]);

  const pageActionItems = [
    <ShareProfileActionButton />
  ];

  return (
    <ModalContext.Provider value={modalContext}>
      <ModularPage
        sidebarContent={<ProfileSuggestions
          profileIdOrSlug={profileIdOrSlug}
        />}
        pageName="Profile"
        pageContent={<ProfilePageContent
          profileIdOrSlug={profileIdOrSlug}
        />}
        pageActionItems={pageActionItems}
        navigationContent={<MemberAppNavigation_Populated currentPage="profile" />}
      />
    </ModalContext.Provider>
  );
};

Profile.propTypes = {
  profileIdOrSlug: string.isRequired
};
