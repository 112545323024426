import React, { useState, useContext, useEffect } from 'react';
import {
  trackEvent, trackIdentity
} from '@paddl/utils-js';
import { TextField, Button } from '@paddl/storybook';
import { EditModalWrapper } from './EditModalWrapper';
import { ModalContext } from '../Context/ModalContext';
import { useMyProfile } from '../Context/MyProfileContext';

export const EditProfileSummary = () => {
  const { openModal, closeModal } = useContext(ModalContext);
  const isOpen = openModal === 'edit-profile-summary';
  const { profile, update, isLoading } = useMyProfile();

  const [summary, setSummary] = useState('');

  useEffect(() => {
    setSummary(profile.aboutMe);
  }, [profile]);

  const submitProfileSummaryData = () => {
    update({ aboutMe: summary }).then(() => {
      trackEvent('Updated profile summary');
      trackIdentity(profile.profileId, { summary });
      closeModal();
    });
  };

  const renderCancelButton = () =>
    <Button
      onClick={closeModal}
      variant="contained"
      color="white"
      data-test-id="cancel-button"
    >
      Cancel
    </Button>;

  const renderSaveButton = () =>
    <Button
      onClick={() => submitProfileSummaryData()}
      variant="contained"
      data-test-id="confirm-button"
      disabled={profile.aboutMe === summary || isLoading}
    >
      Confirm
    </Button>;

  return (
    <EditModalWrapper
      isLarge
      data-test-id="modal-wrapper"
      hasBrandedColour={false}
      isOpen={isOpen}
      setIsOpen={closeModal}
      title="Edit About me"
      subtitle="Share more of your story, goals and aspirations."
      cancelButton={renderCancelButton()}
      saveButton={renderSaveButton()}
    >
      <TextField
        multiline
        isOutlined
        maxLength={1500}
        label="About me"
        data-test-id="about-me"
        placeholder="Share more of your story, goals and aspirations."
        onChange={(value) => setSummary(value)}
        helperText={`Max 1500${summary && summary.length  > 0 ? `/${summary.length}` : ''} Characters`}
        defaultValue={summary || ''}
      />
    </EditModalWrapper>
  );
};
